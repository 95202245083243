import React, { useMemo } from 'react';
import { merge } from '../../ui/utils/merge';

import './styles.css';

interface Props {
    className?: string;
    skill: string;
    level: number;
    color: string;
}

export const Skill: React.FC<Props> = ({ className, skill, level, color }) => {


    const blobs = useMemo(() => {
        const max = 7;

        const out = [];
        for (let i = 0; i < max; i++) {
            out.push(i < level)
        }

        return out;
    }, [level]);

    return <div className={merge("skill", className)}>
        <span className="skill__label">{skill}</span>
        <div className="skill__blobs">
            {blobs.map((selected, i) => {
                return <div key={i} style={{ backgroundColor: selected ? color : undefined }} className="skill__blob skill__blob--yes" />
            })}
        </div>
    </div>
}
